<template>
    <div
        class="auth-footer"
    >
<!--        © {{ copyrightYear }} All Rights Reserved. {{ $store.state.siteName }} LLC-->
        <!-- © {{ copyrightYear }} All Rights Reserved. BigCommand LLC -->
    </div>
</template>

<script>
    export default {
        name: "AuthFooter",
        data: () => ({
            copyrightYear: 2019
        }),
        mounted() {
            let curDate = new Date();
            this.copyrightYear = curDate.getFullYear();
        }
    };
</script>

<style lang="less" scoped>
    @tablet: ~"only screen and (min-width: 720px) and (max-width: 959px)";
    @mobile: ~"only screen and (min-width: 300px) and (max-width: 414px)";

    .auth-footer {
        @media @mobile {
            display: none !important;
        }
        color: #a3bac6;
        font-family: "Helvetica Neue", serif;
        font-size: 13px;//NEMESIS CHANGE font-size: 14px;
        text-align: center;
        bottom: 26px;
        width: 100%;
        font-weight: normal;
    }
</style>
