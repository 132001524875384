<template>
    <div class="support-link">
        <div class="text-right" v-if="whitelabelBrandDetail === null">
            <a href="https://help.bigcommand.com">Help</a>

            <a href="https://bigcommand.com/privacy">Privacy</a>
            <a href="https://bigcommand.com/terms">Terms</a>
        </div>

          <div class="text-right" v-if="whitelabelBrandDetail !== null">
            <a :href="whitelabelBrandDetail.help_link" v-if="whitelabelBrandDetail.help_link !== null && whitelabelBrandDetail.help_link !== ''">Help</a>

            <a :href="whitelabelBrandDetail.privacy_link" v-if="whitelabelBrandDetail.privacy_link !== null && whitelabelBrandDetail.privacy_link !== ''">Privacy</a>
            <a :href="whitelabelBrandDetail.terms_link" v-if="whitelabelBrandDetail.terms_link !== null && whitelabelBrandDetail.terms_link !== ''">Terms</a>
        </div>

    </div>

</template>

<script>
    export default {
        name: "SupportLink",
        props:['whitelabelBrandDetail']
    };
</script>

<style lang="less" scoped>
    @tablet: ~"only screen and (min-width: 720px) and (max-width: 959px)";
    @mobile: ~"only screen and (min-width: 300px) and (max-width: 414px)";

    .support-link {
        margin-top: 5px;
        justify-content: right;
        @media @mobile {
            width: 100%;
            padding-bottom: 14px;
            height: 10%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
        }

        @media @tablet {
            width: 95%;
        }

        a {
            text-decoration: none;
            font-size: 12px;//NEMESIS CHANGE font-size: 13px;
            color: #21455e;
            margin-left: 18px;
            cursor: pointer;
            font-weight: 500;

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                color: #00ACDC;
            }
        }
    }
</style>
