<template>
    <div class="auth-form-header">
        <div :class="{'login-form-item': login}" class="auth-form-logo" v-if="whitelabelBrandDetail === null">
            <img
                alt="Bigcommand"
                class="img-responsive"
                src="../../../assets/img/auth/auth-logo.svg"
            />
            {{ $store.state.siteName }}
        </div>
          <div :class="{'login-form-item': login}" class="auth-form-logo" v-if="whitelabelBrandDetail !== null">
            <img
                alt="Bigcommand"
                class="img-responsive"
                :src="whitelabelBrandDetail.app_logo"
            />
            {{ whitelabelBrandDetail.app_name }}
        </div>
        
        <div :class="{'login-form-item': login}" class="auth-form-header-label">
            <slot name="header-label"></slot>
        </div>

        <div :class="{'login-form-item': login}" class="auth-form-header-sub-label">
            <slot name="sub-header-label"></slot>
        </div>

        <!-- NEMESIS ADD START -->
        <div :class="{'login-form-item-resetpasswd': login}" class="auth-form-header-sub-label">
            <slot name="sub-header-label-resetpasswd"></slot>
        </div>
        <!-- NEMESIS ADD END -->
    </div>
</template>

<script>
    export default {
        name: "AuthFormHeader",
        props: [
            'login',
            'whitelabelBrandDetail'
        ],
        created(){
            // whitelabelBrandDetail
            console.log(this.whitelabelBrandDetail, 'whitelabelBrandDetail');
        },
    };
</script>

<style lang="less" scoped>
    @tablet: ~"only screen and (min-width: 720px) and (max-width: 959px)";
    @mobile: ~"only screen and (min-width: 300px) and (max-width: 960px)";

    .auth-form-header {
        width: 100%;
        margin-top: 37px;
        //NEMESIS ADD START
        padding: 0 40px;
        @media @mobile {
            padding: 0;
            margin-top: 0px;
            padding-top: 83px;
        }
        //NEMESIS ADD END
        .auth-form-logo {
            font-weight: bold;
            display: flex;
            align-items: flex-end;
            // NEMESIS ADD START
            font-size: 20px;
            text-align: left;
            color: #21455e;
            // NEMESIS ADD END
            &.login-form-item {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                @media @mobile {
                    justify-content: left;
                }
                /* NEMESIS REMOVE
                font-weight: bold;
                font-size: 20px;
                text-align: left;
                color: #21455e; */
            }

            img {
                margin-right: 13px;
                width: 62px;
                height: 62px;
            }
        }

        .auth-form-header-label {
            font-weight: 500;
            font-size: 24px;
            text-align: left;
            color: #21455e;
            margin-top: 29px;
            @media @mobile {
                font-size: 18px;
                margin-top: 27px;
            }

            &.login-form-item {
                text-align: center;
                @media @mobile {
                    text-align: left;
                    font-size: 18px;
                }
            }
        }

        .auth-form-header-sub-label {
            font-weight: normal;
            font-size: 16px;
            //NEMESIS REMOVE line-height: 26px;
            color: #21455e;
            margin-top: 18px;//NEMESIS CHANGE margin-top: 13px;

            &.login-form-item {
                text-align: center;
                @media @mobile {
                    text-align: left;
                }
            }
            //NEMESIS ADD START
            &.login-form-item-resetpasswd {
                text-align: center;
                padding:0 45px;
                @media @mobile {
                    text-align: left;
                    padding: 0;
                }
            }
            //NEMESIS ADD END
            .btn {
                &.auth-form-header-sub-label-email {
                    border: 2px solid #e2e5ed !important;//NEMESIS CHANGE border: 1px solid #e2e5ed !important;
                    display: inline-flex;
                    padding: 2px 2px 2px 2px !important;//NEMESIS CHANGE padding: 0.5rem 1.5rem 0.5rem 0.5rem !important;
                    justify-content: center;
                    align-items: center;
                    border-radius: 20px;

                    /*NEMESIS REMOVE .far.fa-user-circle {
                        font-size: 28px;
                        font-weight: normal;
                        opacity: 0.8;
                    }

                    .fas.fa-chevron-down {
                        opacity: 0.8;
                    }*/

                    .login-selected-email {
                        margin: 0 10px;//NEMESIS CHANGE margin: 0 1rem;
                        font-weight: normal;
                        color: var(--color-content);
                    }
                }
            }

            .email-sent-done {
                border-radius: 50px;
                display: flex;
                margin: 0 -10px;
                color: #0ad688;
                background-color: #0ad68810;
                padding:8px;//NEMESIS CHANGE padding: 10px 15px;

                img.img-responsive {
                    @media @mobile {
                        display: none;
                    }
                }
                div.block {
                    margin-left: 20px;//NEMESIS CHANGE margin-left: 2rem;
                    text-align: left;

                    .success-title {
                        font-size: 16px;
                        font-weight: 700;   //NEMESIS ADD
                    }

                    .success-message {
                        font-size: 14px;
                        margin-top: 1rem;
                    }
                }
            }
        }

        &.register-form-header {
            margin-top: 0;
            padding-left: 0;
        }
    }
</style>
