<template>
    <div class="auth-form-header-border">
        <div class="auth-form-header-center"></div>
    </div>
</template>

<script>
    export default {
        name: "AuthFormProgress"
    };
</script>

<style lang="less" scoped>
    @tablet: ~"only screen and (min-width: 720px) and (max-width: 959px)";
    @mobile: ~"screen and (max-width: 414px)";

    .auth-form-header-border {
        border: 0;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 465.7px;
        height: 6px;
        background: #e2e5ed;
        @media @mobile {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            width: 100%;
        }
        .auth-form-header-center {
            width: 65%;
            @media @mobile {
                width: 35%;
            }
            height: 6px;
            background-color: #0dabd8;
            position: absolute;
            left: -35%;
            @media @mobile {
                left: 0%;
            }
            animation-play-state: running;
            animation: auth-header-bar-animation 2s infinite linear;
        }
    }

    @keyframes auth-header-bar-animation {
        0% {
            left: -60%;
            @media @mobile {
                left: 0%;
            }
        }
        100% {
            left: 95%;
        }
    }
</style>
